.navbar {
  box-shadow: none !important;
}
.header-titles {
  &:hover {
    color: white;
  }
}

.menu-text {
  &:hover {
    color: white;
  }
}
#menu-appbar {
  width: 100%;
  align-items: center;
}

.paperClass {
  background: black !important;
  left: 75% !important;
  top: 8% !important;
  max-width: 20% !important;
  width: 20% !important;
}
@media only screen and (max-width: 667px) {
  .paperClass {
    background: black !important;
    left: 0 !important;
    top: 0% !important;
    max-width: 100% !important;
    width: 100% !important;
  }
}

.header-image-container {
  cursor: pointer;
  img {
    height: 250px;
    width: 250px;
  }
}
@media only screen and (max-width: 667px) {
  .header-image-container {
    img {
      height: 150px;
      width: 150px;
    }
  }
  .header-image-container {
    cursor: pointer;
  }
  .login-btn {
    font-size: 1rem !important;
  }
  .start-trading-btn {
    font-size: 1rem !important;
  }
}
.navbar-on-scroll {
  background: linear-gradient(360deg, #353535, #000000);
  box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.7);
  display: none;
}
.header-logo {
  display: flex;
  justify-content: center;
}

// .MuiMenu-paper {
//   background: black !important;
//   left: 75% !important;
//   top: 8% !important;
//   max-width: 20% !important;
//   z-index: 999999 !important;
//   width: 20% !important;
// }
// @media only screen and (max-width: 667px) {
//   .MuiMenu-paper {
//     background: black !important;
//     left: 0 !important;
//     top: 7% !important;
//     max-width: 100% !important;
//     width: 100% !important;
//     z-index: 999999 !important;
//   }
// }
