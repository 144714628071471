.comparison-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 900px;
  @media only screen and (max-width: 667px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding-bottom: 30%;
  }
}

.comparison-inner-container {
  height: 550px;
  width: 25%;
  margin: 1rem;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  background-image: linear-gradient(0deg, #07232e, black);

  @media only screen and (max-width: 667px) {
    width: 80%;
    margin: 0 auto;
    padding: 1rem;
    margin-bottom: 10rem;
  }
  ul {
    width: 75%;
    margin: 0 auto;
    text-align: left;
    li {
      text-align: left;
      font-size: 1rem;
    }
  }
  h2 {
    text-align: center;
    margin: 0 auto;
    font-size: 1.5rem;
    color: gainsboro;
    // font-weight: bold;
  }
}

.comparison-inner-container-title {
  text-align: center;
  margin-top: 2rem;
}
